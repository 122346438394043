<template>
    <div>
        <Toast position="top-center" group="tc"></Toast>
        <div class="card">
            <div class="mb-3" style="justify-content:space-between;display: flex;border-radius: 4px">
                <div>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" style="width:20rem" placeholder="搜索FNSKU、SKU、ASIN、货件编号" />
                    </span>
                    <Button type="button" icon="pi pi-refresh" label="刷新" class="ml-3" @click="getShipmentList()"/>
                    <Button type="button"  label="创建货件" class="ml-3" @click="createShipment()"/>
                </div>
                <div>
                    <Button type="button" icon="pi pi-filter-slash" label="清除" class="p-button-outlined" @click="initFilters()"/>
                </div>
            </div>
            <DataTable scrollable scrollHeight="calc(100vh - 16.5rem)" :value="shipments" v-model:expandedRows="expandedItems" :paginator="true" :rowsPerPageOptions="[10,20,50]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="货件总数：{totalRecords}" :rows="20"
                dataKey="shipmentId" v-model:filters="filters" :globalFilterFields="['asins','sellerSKUs','fnskus','shipmentId']" filterDisplay="menu" :loading="loading" responsiveLayout="scroll">
                
                <template #empty>
                    {{isempty}}
                </template>
                
                <Column :expander="true" style="max-width:3rem" />
                <Column field="shipmentName" header="货件名称" style="min-width:12rem" />

                <Column header="货件编号" style="min-width:12rem">
                    <template #body="{data}">
                       {{data.shipmentId}}
                        <Button icon="pi pi-clone" class="p-button-rounded p-button-outlined p-button-sm" style="border:none;" title="复制" @click="copy(data.shipmentId)" />
                    </template>
                </Column>

                <Column field="shipmentStatus" header="货件状态" :showFilterMatchModes="false" :showFilterOperator="false" style="min-width:3rem">
                    <template #body="{data}">
                        <span>{{translates[data.shipmentStatus]}}</span>
                    </template>
                    <template #filter="{filterModel}">
                            <Dropdown v-model="filterModel.value" :options="shipmentStatus" placeholder="全部" class="p-column-filter">
                                <template #value="slotProps">
                                    <span class="customer-badge" v-if="slotProps.value">{{translates[slotProps.value]}}</span>
                                    <span v-else>{{slotProps.placeholder}}</span>
                                </template>
                                <template #option="slotProps">
                                    <span class="customer-badge">{{translates[slotProps.option]}}</span>
                                </template>
                            </Dropdown>
                        </template>
                </Column>

                <Column field="store" header="店铺" :filterMenuStyle="{'height':'5.3rem'}" :showClearButton="false" :showApplyButton="false" :showFilterMatchModes="false" :showFilterOperator="false" style="min-width:3rem">
                    <template #filter="{filterModel,filterCallback}">
                            <Dropdown v-model="filterModel.value" @change="filterCallback()" :showClear="true" :options="stores" placeholder="全部" optionLabel="storeName" optionValue="storeName" class="p-column-filter"/>
                        </template>
                </Column>

                <Column field="updateTime" header="更新时间" style="min-width:1rem" />
                <Column field="SKUCount" header="SKU数量" style="min-width:1rem" />
                <Column field="quantityShipped" header="已发货" style="min-width:1rem" />
                <Column field="quantityReceived" header="已收到" style="min-width:2rem" />
                <Column field="difference" header="货件差异" style="min-width:1rem"/>
                <Column field="destinationFulfillmentCenterId" header="仓库编码" style="min-width:1rem"/>

                <Column field="statusAndChecked" header="操作" :showFilterMatchModes="false" style="min-width:1rem">
                    <template #body="{data}">
                        <Tag v-if="data.statusAndChecked.includes('1')" value="已核对" severity="success" rounded></Tag>
                        <span v-else-if="data.statusAndChecked=='CLOSED0'">
                        <Tag value="待核对" severity="warning" rounded></Tag>        
                        <Button icon="pi pi-check" class="p-button-rounded p-button-outlined p-button-success p-button-sm" style="border:none;" title="核对货件" @click="checkShipment(data.shipmentId)" />
                        </span>
                        <Tag v-else value="未完成" rounded></Tag>
                    </template>
                    <template #filter="{filterModel}">
                        <Dropdown v-model="filterModel.value" :options="checks" placeholder="全部" class="p-column-filter" optionLabel="name" optionValue="value"></Dropdown>
                    </template>
                </Column>
                <template #expansion="{data}">
                    <div class="card mb-0" style="width:100%">
                    <DataTable :value="data.items" responsiveLayout="scroll">
                        <Column header="SKU" :headerStyle="{padding:'0.3rem 0.5rem'}">
                            <template #body="{data}">
                                {{data.sellerSKU}}
                                <Button icon="pi pi-clone" class="p-button-rounded p-button-outlined p-button-sm" style="border:none;" title="复制" @click="copy(data.sellerSKU)" />
                            </template>
                        </Column>
                        <Column header="FNSKU" :headerStyle="{padding:'0.3rem 0.5rem'}">
                            <template #body="{data}">
                                {{data.fnsku}}
                                <Button icon="pi pi-clone" class="p-button-rounded p-button-outlined p-button-sm" style="border:none;" title="复制" @click="copy(data.fnsku)" />
                            </template></Column>
                        <Column header="ASIN" :headerStyle="{padding:'0.3rem 0.5rem'}">
                            <template #body="{data}">
                                {{data.asin}}
                                <Button icon="pi pi-clone" class="p-button-rounded p-button-outlined p-button-sm" style="border:none;" title="复制" @click="copy(data.asin)" />
                            </template></Column>
                        <Column field="quantityShipped" header="已发送" :headerStyle="{padding:'0.3rem 0.5rem'}"></Column>
                        <Column field="quantityReceived" header="已接收" :headerStyle="{padding:'0.3rem 0.5rem'}"></Column>
                        <Column header="差异" :headerStyle="{padding:'0.3rem 0.5rem'}">
                            <template #body="{data}">
                                {{data.quantityReceived-data.quantityShipped}}
                            </template>
                        </Column>
                    </DataTable>
                    </div>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
import Service from '../../service/Service';
import {FilterMatchMode} from 'primevue/api';
import useClipboard from 'vue-clipboard3'

export default {
    data() {
        return {
            user:null,
            shipments: [],
            expandedItems:[],
            filters: {},
            stores: [],
            shipmentStatus:["CLOSED","RECEIVING","IN_TRANSIT","WORKING","SHIPPED"],
            checks:[{name:'待核对',value:'CLOSED0'},{name:'已核对',value:'1'},{name:'未完成',value:'0'}],
            translates: {
                CLOSED:'已完成',
                RECEIVING: '接收中', 
                IN_TRANSIT:'运输中',
                WORKING:'处理中',
                SHIPPED:'已发货'
            },
            loading: true,
            isempty:''
        }
    },
    created() {
        this.service = new Service();
        this.initFilters();
    },
    mounted() {
        this.user=JSON.parse(localStorage.getItem('user'));
        this.stores=JSON.parse(localStorage.getItem('stores'));
        this.getShipmentList();
    },
    watch:{
    },
    methods: {
        showSuccess(mes='核对成功!') {
            this.$toast.add({severity: 'success', summary: mes,  group: 'tc',life:1000});
        },
        showError(mes='核对失败!') {
            this.$toast.add({severity: 'error', summary: mes,  group: 'tc',life:1000});
        },
        createShipment(){
            this.$router.push({name:"createshipment"});
        },
        getShipmentList(){
            this.isempty='正在加载...';
            this.shipments = null; 
            this.loading = true;
            var url='shipment/getshipmentlist.php';
            this.service.sendHttp(url,'POST').then(res=>res.json()).then(data => {
                this.loading = false;
                this.isempty='无数据！';
                if(data.result=='success'){
                    data.shipments.forEach(shipment=>{
                        shipment.statusAndChecked=shipment.shipmentStatus+shipment.checked;
                        shipment.SKUCount=shipment.items.length;
                        shipment.items.forEach(item=>{
                            if(shipment.quantityReceived){
                                shipment.quantityReceived+=parseInt(item.quantityReceived);
                            }
                            else{
                                shipment.quantityReceived=parseInt(item.quantityReceived);
                            
                            }
                            if(shipment.quantityShipped){
                                shipment.quantityShipped+=parseInt(item.quantityShipped);
                            }
                            else{
                                shipment.quantityShipped=parseInt(item.quantityShipped);
                            }
                            if(shipment.sellerSKUs){
                                shipment.sellerSKUs+=item.sellerSKU;
                            }else{
                                shipment.sellerSKUs=item.sellerSKU;
                            }
                            if(shipment.asins){
                                shipment.asins+=item.asin;
                            }else{
                                shipment.asins=item.asin;
                            }
                            if(shipment.fnskus){
                                shipment.fnskus+=item.fnsku;
                            }
                            else{
                                shipment.fnskus=item.fnsku;
                            }
                        });
                        shipment.difference=shipment.quantityReceived-shipment.quantityShipped;
                    });
                    this.shipments = data.shipments; 
                }
            });
        },
        checkShipment(shipmentId){
            var url='shipment/checkshipment.php?shipmentId='+shipmentId;
            this.service.sendHttp(url).then(res=>res.json()).then(data=>{
                if(data.result=='success'){
                    this.showSuccess();
                    this.shipments.forEach(shipment => {
                        if(shipment.shipmentId==shipmentId){
                            shipment.checked='1';
                            shipment.statusAndChecked=shipment.ShipmentStatus+shipment.checked;
                        }
                         
                    });
                }
                else{
                    this.showError();
                }
            });
       },
        initFilters() {
            this.filters = {
                'store': {value: null, matchMode: FilterMatchMode.EQUALS},
                'shipmentStatus': {value: null, matchMode: FilterMatchMode.EQUALS},
                'statusAndChecked': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
            }
            this.keyword='';
        },
        copy(text){
            const { toClipboard } = useClipboard();
            toClipboard(text).then(
                ()=>{this.showSuccess('复制成功！')},
                ()=>{this.showError('复制失败！')}
            );
        }
    }
}
</script>